import React from "react"
import { useMemo } from "react"
import CourseCard from "./basics/courseCard"

const getChapterCountByCourse = chaptersEdges =>
  chaptersEdges.reduce((acc, { node: { fields: { course } } }) => {
    const chapterCountByCourse = { ...acc }

    if (chapterCountByCourse[course]) {
      chapterCountByCourse[course] += 1
    } else {
      chapterCountByCourse[course] = 1
    }

    return chapterCountByCourse
  }, {})

const FeaturedCourses = ({ coursesEdges, chaptersEdges }) => {
  // console.log("courses: ", coursesEdges, "chapters: ", chaptersEdges)
  const chapterCountByCourse = useMemo(
    () => getChapterCountByCourse(chaptersEdges),
    [chaptersEdges]
  )

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 col-gap-10 row-gap-10">
        {coursesEdges.map(course => (
          <CourseCard
            key={course.node.fields.slug}
            slug={course.node.fields.slug}
            title={course.node.frontmatter.title}
            themeColor={course.node.frontmatter.themeColor}
            description={course.node.frontmatter.description}
            thumbImagePath={course.node.frontmatter.thumbImagePath}
            chapterCount={chapterCountByCourse[course.node.fields.course]}
            counting={course.node.frontmatter.counting}
          />
        ))}
      </div>
    </>
  )
}

export default FeaturedCourses
