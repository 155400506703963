import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturedCourses from "../components/courses"
import WhyBsccsit from "../components/why-bsccsit"

const IndexPage = () => {
  const { courses, chapters } = useStaticQuery(
    graphql`
      query {
        courses: allMarkdownRemark(
          filter: { fields: { pageType: { eq: "course" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                description
                themeColor
                thumbImagePath
                counting
              }
              fields {
                slug
                course
              }
            }
          }
        }
        chapters: allMarkdownRemark(
          filter: { fields: { pageType: { eq: "chapter" } } }
        ) {
          edges {
            node {
              fields {
                course
              }
            }
          }
        }
      }
    `
  )

  const shuffledListOfCourses = courses.edges.sort(() => 0.5 - Math.random())

  const featuredCourses =
    shuffledListOfCourses.length > 4
      ? shuffledListOfCourses.slice(0, 4)
      : shuffledListOfCourses.slice(0, shuffledListOfCourses.length)

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <h2 className="font-bold uppercase text-2xl border-b-2 pb-1 my-10">
        Courses
      </h2>
      <FeaturedCourses
        coursesEdges={featuredCourses}
        chaptersEdges={chapters.edges}
      />
      <WhyBsccsit />
    </Layout>
  )
}

export default IndexPage
