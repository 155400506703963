import React from "react"
import { Link } from "gatsby"

const CourseCard = ({
  slug,
  title,
  description,
  thumbImagePath,
  themeColor,
  chapterCount,
  counting,
}) => (
  <Link to={slug}>
    <div
      className={`rounded-lg p-4 h-full flex flex-col justify-between ${themeColor}`}
    >
      <div className="flex flex-col h-full justify-between">
        <div>
          <h2 className="font-bold text-xl">{title}</h2>
          <p className="py-4 leeading-snug text-sm">{description}</p>
        </div>
        <p className="uppercase tracking-widest font-bold text-sm">
          {chapterCount && <span>{chapterCount}</span>}{" "}
          {chapterCount === 1 ? "Chapter" : "Chapters"}
          {counting && <span> & Counting</span>}
        </p>
      </div>
      <div className="py-4">
        <img src={thumbImagePath} alt={`thumbnail for ${title} course`} />
      </div>
    </div>
  </Link>
)

export default CourseCard
