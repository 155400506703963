import React from "react"

const WhyBsccsit = () => (
  <div className="pt-10">
    <div className="-mx-16 px-16 grid grid-cols-1 md:grid-cols-2 col-gap-16 row-gap-6 py-16 items-center">
      <div>
        <h3 className=" font-bold text-xl md:text-5xl max-w-xl text-gray-900 leading-tight">
          What will you learn?
        </h3>
        <hr className="border-b-2 w-16 border-blue-600" />
        <p className="text-gray-800 leading-relaxed mt-8">
          bsccsit.com is a learning website based on the curriculum requirement
          of the BSCCSIT program of TU. It is a complete learning package for
          students enrolled in the BSCCSIT program as it consists of all the
          right resources from curriculum outlines to the required learning
          materials. Other IT majors can also benefit from the contents.
        </p>
        <p className="text-gray-800 leading-relaxed mt-2">Happy Learning!</p>
      </div>
      <div className="px-4">
        <video autoPlay muted playsInline loop>
          <source src="/Home_Work.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
)

export default WhyBsccsit
